module.exports = {
    // iam用户中心基础url
    getIamUrl: () => {
        let iamUrl = ''
        if (window.location.origin.indexOf('fat') > 0 && window.location.origin.indexOf('https') != -1) {
            iamUrl = 'https://api.fat.driverglobe.com'
        } else if (window.location.origin.indexOf('fat') > 0 && window.location.origin.indexOf('https') == -1) {
            iamUrl = 'http://api.fat.driverglobe.com'
        }  else if (window.location.origin.indexOf('localhost') > 0) {
            iamUrl = 'http://api.fat.driverglobe.com'
        } else if (window.location.origin.indexOf('carrierglobe') > 0) {
            iamUrl = 'https://api.carrierglobe.com'
        }
        if (process.env.NODE_ENV === 'development') {
          iamUrl = 'api'
        }
        return iamUrl
    },
    loadJs: (src) => {
        return new Promise((resolve,reject)=>{
          let script = document.createElement('script');
          script.type = "text/javascript";
          script.src= src;
          document.body.appendChild(script);
          script.onload = ()=>{
            resolve();
          }
          script.onerror = ()=>{
            reject();
          }
        })
      }
};
